import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { Row, Col } from 'react-bootstrap'
import { renderRichText } from '../lib/richTextRender'
import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'
import NewsLetterForm from '../components/newsLetterForm'

class Contact extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [contact] = get(this, 'props.data.allContentfulKontakt.edges')

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={siteTitle} />
          <div className="wrapper p-3">
            <h2 className="section-headline font-weight-bold">Kontakt</h2>
            <div>{renderRichText(contact.node.text.json)}</div>
            <Row>
              <Col md={6}>{renderRichText(contact.node.option1.json)}</Col>
              <Col md={6}>{renderRichText(contact.node.option2.json)}</Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                {renderRichText(contact.node.newsletter.json)}
                <NewsLetterForm />
              </Col>
              <Col md={6}>{renderRichText(contact.node.socialMedia.json)}</Col>
            </Row>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query contactQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulKontakt {
      edges {
        node {
          socialMedia {
            json
          }
          text {
            json
          }
          option1 {
            json
          }
          option2 {
            json
          }
          newsletter {
            json
          }
        }
      }
    }
  }
`
